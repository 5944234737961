// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-abbonamenti-tsx": () => import("./../../../src/pages/abbonamenti.tsx" /* webpackChunkName: "component---src-pages-abbonamenti-tsx" */),
  "component---src-pages-academycup-index-tsx": () => import("./../../../src/pages/academycup/index.tsx" /* webpackChunkName: "component---src-pages-academycup-index-tsx" */),
  "component---src-pages-calendario-index-tsx": () => import("./../../../src/pages/calendario/index.tsx" /* webpackChunkName: "component---src-pages-calendario-index-tsx" */),
  "component---src-pages-classifica-index-tsx": () => import("./../../../src/pages/classifica/index.tsx" /* webpackChunkName: "component---src-pages-classifica-index-tsx" */),
  "component---src-pages-contatti-index-tsx": () => import("./../../../src/pages/contatti/index.tsx" /* webpackChunkName: "component---src-pages-contatti-index-tsx" */),
  "component---src-pages-diventa-sponsor-index-tsx": () => import("./../../../src/pages/diventa-sponsor/index.tsx" /* webpackChunkName: "component---src-pages-diventa-sponsor-index-tsx" */),
  "component---src-pages-giovanili-academy-tsx": () => import("./../../../src/pages/giovanili/academy.tsx" /* webpackChunkName: "component---src-pages-giovanili-academy-tsx" */),
  "component---src-pages-giovanili-index-tsx": () => import("./../../../src/pages/giovanili/index.tsx" /* webpackChunkName: "component---src-pages-giovanili-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-newsletter-index-tsx": () => import("./../../../src/pages/newsletter/index.tsx" /* webpackChunkName: "component---src-pages-newsletter-index-tsx" */),
  "component---src-pages-prima-squadra-index-tsx": () => import("./../../../src/pages/prima-squadra/index.tsx" /* webpackChunkName: "component---src-pages-prima-squadra-index-tsx" */),
  "component---src-pages-societa-hall-of-fame-tsx": () => import("./../../../src/pages/società/hall-of-fame.tsx" /* webpackChunkName: "component---src-pages-societa-hall-of-fame-tsx" */),
  "component---src-pages-societa-index-tsx": () => import("./../../../src/pages/società/index.tsx" /* webpackChunkName: "component---src-pages-societa-index-tsx" */),
  "component---src-pages-societa-la-storia-tsx": () => import("./../../../src/pages/società/la-storia.tsx" /* webpackChunkName: "component---src-pages-societa-la-storia-tsx" */),
  "component---src-pages-societa-organigramma-tsx": () => import("./../../../src/pages/società/organigramma.tsx" /* webpackChunkName: "component---src-pages-societa-organigramma-tsx" */),
  "component---src-pages-ticketing-index-tsx": () => import("./../../../src/pages/ticketing/index.tsx" /* webpackChunkName: "component---src-pages-ticketing-index-tsx" */),
  "component---src-templates-player-index-tsx": () => import("./../../../src/templates/Player/index.tsx" /* webpackChunkName: "component---src-templates-player-index-tsx" */),
  "component---src-templates-squadra-giovanile-index-tsx": () => import("./../../../src/templates/SquadraGiovanile/index.tsx" /* webpackChunkName: "component---src-templates-squadra-giovanile-index-tsx" */),
  "component---src-templates-static-page-index-tsx": () => import("./../../../src/templates/StaticPage/index.tsx" /* webpackChunkName: "component---src-templates-static-page-index-tsx" */),
  "component---src-templates-story-page-index-tsx": () => import("./../../../src/templates/StoryPage/index.tsx" /* webpackChunkName: "component---src-templates-story-page-index-tsx" */),
  "component---src-templates-wecare-index-tsx": () => import("./../../../src/templates/wecare/index.tsx" /* webpackChunkName: "component---src-templates-wecare-index-tsx" */)
}

